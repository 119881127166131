import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss'],
  standalone: true,
  imports: [RouterLink],
})
export class Error404Component {}
